// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

@import '~sweetalert2/src/variables';

@import "vue-select/src/scss/vue-select.scss";

.custom-input-wrapper{
    display: inline-block;
}

.custom-input{
    position: relative;
    height: 100px;
    width: 100px;
    cursor: pointer;
    border-radius: 200px;
    border: 2px solid;
    background-color: lightgray;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.btn-primary,.btn-danger{
	color:#fff!important;
}
th,td{
    outline: none !important;
}

.badge-daily{
	background-color:#BFBFBF;
}
.badge-alternate{
	background-color:#d99694;
}
.badge-twice_per_week{
	background-color:#c3d69b;
}
.badge-weekly{
	background-color:#b3a2c7;
}
.badge-two_weeks{
	background-color:#93cddd;
}
.badge-monthly,
.badge-bimonthly,
.badge-trimonthly,
.badge-semestral,
.badge-annual{
	background-color:#fac090;
}
.help-button{
    font-size: 15pt;
    border-radius: 35px;
    padding: 6px 14px;
    box-shadow: 1px 1px 3px grey;
    position: fixed;
    bottom: 10px;
    right: 10px;
}
.btn-app.exervis {
	width: 100px;
    height: auto;
}
.btn-app.exervis i{
    font-size: 30px;
}
.card-background {
    display: block;
    background-image: linear-gradient(127deg, #2f90b8 0%, #84afbf 91%);
    box-shadow: 0 2px 48px 0 rgb(0 0 0 / 6%);
    font-weight: 400;
    font-size: 16px;
    color: #647E95;
    line-height: 28px;
    letter-spacing: 0.88px;
    padding: 30px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 15px;
    position: relative;
    overflow: hidden;
}
.card-background.exit{
    background-color: #dc3545;
    background-image: none;
}
.card-background h5{
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    color: white;
    margin-bottom:0px;
    letter-spacing: 0.7px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    position: relative;
    z-index: 2;
}
.permissions.VueTables__child-row > td{
    padding:0;
}
.permissions.VueTables__child-row .VuePagination{
    display:none;
}
.permissions.VueTables__child-row table{
    margin-bottom:0;
}
.clienttypes table .actions{
    width:105px;
}
.loading-box{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgb(0 0 0 / 30%);
    left: 0;
    top: 0;
    z-index: 10000;
}
.loading-box .loading-box-container{
    margin: auto;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 320px;
    max-width: 100%;
    height: 160px;
    background-color: white;
    padding: 45px 15px;
    text-align: center;
    border-radius: 10px;
}
.loading-box .loading-box-container i{
    font-size: 21pt;
}
.loading-box .loading-box-container span{
    display: block;
    font-size: 18pt;
    margin-top: 15px;
}

.VueTables__child-row > td{
    padding:0!important;
    border-top: 2px solid #666;
    border-bottom: 2px solid #666;
}
.VueTables__child-row .table{
    margin-bottom:0px;
}


.modal-fullscreen.modal-dialog {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0 !important;
    max-width: 100%;
}

.modal-fullscreen .modal-content {
    border-radius: 0;
}

.modal-fullscreen .modal-body{
    min-height: calc(100vh - 136px);
}

.file-wrapper{
    border: 1px solid silver;
    padding: 10px;
}

.document-thumbnail{
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;
    height: 130px;
}

.column-actions{
    min-width: 90px;
}
.vs__dropdown-toggle{
    padding: 4px 0 !important;
}
input[type=text]{
    text-transform: uppercase;
}
.badge-success-dark{
    color: #fff;
    background-color: #0d7224;
}